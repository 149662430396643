import {Controller} from "stimulus";

export default class extends Controller {

  initialize() {
    this.boundFormat = this.format.bind(this);
    import("libphonenumber-js").then(libphone => {
      this.ayt = libphone.AsYouType;
      this.initialized = true;
      this.format();
    })
  }

  connect() {
    this.element.addEventListener('input', this.boundFormat)
  }

  disconnect() {
    this.element.removeEventListener('input', this.boundFormat)
  }

  format() {
    if (this.initialized && this.element.value !== '') {
      this.element.value = new this.ayt(this.country).input(this.element.value);
    }
  }

  get country() {
    return this.data.get('country') || 'US';
  }

}