import {Controller} from "stimulus"

export default class extends Controller {
  initialize() {
    this.element.addEventListener('blur', event => {
      this.modified(event);
    });
  }

  connect() {
  }

  modified(event) {
    let value = this.element.value.trim();
    this.setActive(value);
    this.element.classList.toggle('error', (this.element.required && value == ''));
    if (this.element.checkValidity() == false) {
      this.element.classList.toggle('error', true);
    }
  }

  setActive(value) {
    if ((value === '') && (this.element.selectedIndex !== undefined)) {
      let option = this.element.options[this.element.selectedIndex];
      this.element.classList.toggle('active', option.innerText.trim() !== '');
    }
    else {
      this.element.classList.toggle('active', value != '');
    }
  }

}